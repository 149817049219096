import React from "react";
import { graphql, Link, PageProps, useStaticQuery } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import PageTitle from "../components/PageTitle";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import BlogPostList from "../components/BlogPostList";
import Section from "../components/Section";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 700,
            margin: "0 auto",
            paddingBottom: 60,
        },
        desc: {
            marginBottom: 32,
        },
    })
);

const PreviewBlog: React.FC<PageProps> = (props) => {
    const classes = useStyles();

    return (
        <Layout section="blog">
            <SEO title="Preview" path={props.path} />
            <Section content first last>
                <PageTitle title="Preview" />
                <p className={classes.desc}>
                    Below are all the blog articles written including those that
                    have yet to be published. Highlighted articles are not
                    published.
                </p>
                <BlogPostList all />
            </Section>
        </Layout>
    );
};

export default PreviewBlog;
